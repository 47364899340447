import React from "react";
import { Helmet } from "react-helmet";
import "./App.scss";
import Main from "./containers/Main";

function App() {
  return (
    <div>
      <Helmet>
        <title>Rahul Ladumor: Senior Software Engineer | Full-Stack Developer | Cloud Architect | AI/ML Expert | DevOps Specialist</title>
        <meta name="description" content="Rahul Ladumor: Experienced IT professional specializing in software engineering, full-stack development, cloud solutions, AI/ML, DevOps, and cybersecurity. Delivering innovative tech solutions for digital transformation." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://rahulladumor.in/" />
        <meta property="og:title" content="Rahul Ladumor - IT Solutions Expert | Full-Stack Development | Cloud Architecture | AI/ML | DevOps | Cybersecurity" />
        <meta property="og:description" content="Partner with Rahul Ladumor for cutting-edge IT solutions. Expertise in software engineering, full-stack development, cloud architecture, AI/ML, DevOps, and cybersecurity. Driving digital transformation for businesses globally." />
        <meta property="og:image" content="https://rahulladumor.in/rahul-social-share.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://twitter.com/Rahul__ladumor" />
        <meta property="twitter:title" content="Rahul Ladumor - IT Solutions Expert | Full-Stack Development | Cloud Architecture | AI/ML | DevOps | Cybersecurity" />
        <meta property="twitter:description" content="Partner with Rahul Ladumor for cutting-edge IT solutions. Expertise in software engineering, full-stack development, cloud architecture, AI/ML, DevOps, and cybersecurity. Driving digital transformation for businesses globally." />
        <meta property="twitter:image" content="https://rahulladumor.in/rahul-social-share.png" />
        <meta name="keywords" content="software engineering, full-stack development, cloud computing, artificial intelligence, machine learning, DevOps, cybersecurity, agile methodologies, IT consulting, digital transformation, big data, blockchain, IT infrastructure, web development, mobile app development" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Person",
              "name": "Rahul Ladumor",
              "url": "https://rahulladumor.in/",
              "image": "https://rahulladumor.in/rahul-social-share.png",
              "sameAs": [
                "https://twitter.com/Rahul__ladumor",
                "https://www.linkedin.com/in/rahulladumor",
                "https://github.com/Rahulladumor"
              ],
              "jobTitle": "Senior Software Engineer & IT Solutions Architect",
              "worksFor": {
                "@type": "Organization",
                "name": "Global IT Solutions Provider",
                "url": "https://rahulladumor.in/"
              },
              "alumniOf": "Top Tech University",
              "description": "Rahul Ladumor is a leading IT solutions expert, specializing in software engineering, full-stack development, cloud architecture, AI/ML, DevOps, and cybersecurity. With extensive experience in delivering innovative technology solutions worldwide, Rahul helps businesses achieve digital transformation and gain a competitive edge in the rapidly evolving tech landscape."
            }
          `}
        </script>
      </Helmet>
      <Main />
    </div>
  );
}

export default App;
