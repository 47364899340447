/* Portfolio Configuration */

// Global color settings can be modified in the _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Ensure this matches your custom animation file name

// Splash Screen Configuration
const splashScreen = {
  enabled: true, // Toggle splash screen
  animation: splashAnimation,
  duration: 2000 // Animation duration in milliseconds
};

// Greeting Section Configuration
const illustration = {
  animated: true // Set to false for static SVG
};

const greeting = {
  username: "Rahul Ladumor",
  title: "Hello, I'm Rahul Ladumor",
  subTitle: emoji(
    "🚀 AWS Certified Solutions Architect | Full Stack Developer | DevOps Engineer | Cloud Expert | Serverless Enthusiast"
  ),
  resumeLink: "https://drive.google.com/file/d/1G_27Ve4CnAKFbkpT4n616O0s2h6Z9wiH/view?usp=share_link",
  displayGreeting: true
};

// Social Media Links
const socialMediaLinks = {
  github: "https://github.com/Rahulladumor",
  linkedin: "https://www.linkedin.com/in/rahulladumor/",
  gmail: "rahuldladumor@gmail.com",
  medium: "https://medium.com/@ladumorrahul",
  // Add or remove social media links as needed
  display: true // Set to false to hide this section
};

// Skills Section Configuration
const skillsSection = {
  title: "Professional Expertise",
  subTitle: "AWS-Certified Full Stack Developer & DevOps Specialist",
  skills: [
    emoji(
      "⚡ Cloud Architecture: Proficient in AWS, GCP, Azure, with expertise in containerization (Docker, Kubernetes) and Infrastructure as Code (Terraform, Ansible)"
    ),
    emoji(
      "🔧 Full Stack Development: Building scalable serverless applications using Node.js, Express.js, React, and serverless frameworks"
    ),
    emoji(
      "🗄️ Database Management: Extensive experience with MongoDB, DynamoDB, and Elasticsearch for optimized data solutions"
    ),
    emoji(
      "🌐 Modern Web Development: Crafting responsive and performant web applications using latest JavaScript standards and frameworks"
    ),
    emoji(
      "🚀 DevOps & CI/CD: Implementing robust DevOps practices with AWS, Azure, and other cloud platforms for streamlined development workflows"
    ),
    emoji(
      "☁️ Cloud Hosting: Expertise in deploying and managing applications on AWS EC2, ECS, and other cloud services"
    ),
    emoji(
      "🤖 IoT & Mobile Development: Creating innovative IoT solutions and mobile applications with React Native"
    ),
    emoji(
      "🔌 Real-Time Communication: Implementing WebSocket technology for responsive, real-time application features"
    ),
    emoji(
      "🗣️ Voice Technology: Developing Alexa Skills to enhance user interaction through voice-activated functionalities"
    ),
  ],

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Node.js",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "Firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "fab fa-git"
    }
  ],
  display: true // Set to false to hide this section
};

// Education Section Configuration
const educationInfo = {
  display: true, // Set to false to hide this section
  schools: [
    {
      schoolName: "Veer Narmad South Gujarat University, Surat",
      logo: require("./assets/images/Veer Narmad South Gujarat University, Surat_Logo.jpg"),
      subHeader: "Master of Science in Information & Technologies",
      duration: "2016 - 2020",
      desc: "AWS Cloud Practitioner Certified. Developed enterprise-level projects using .NET C#, Java, and React Native, showcasing proficiency in software engineering, web development, mobile app creation, and UI/UX design.",
      descBullets: []
    },
  ]
};

// Technical Proficiency Section
const techStack = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "Backend Development (Node.js)",
      progressPercentage: "95%"
    },
    {
      Stack: "Cloud Architecture & DevOps",
      progressPercentage: "90%"
    },
    {
      Stack: "Full Stack Development",
      progressPercentage: "85%"
    },
    {
      Stack: "Serverless Technologies",
      progressPercentage: "80%"
    }
  ],
  displayCodersrank: false // Set to true to display codersrank badges
};

// Work Experience Section
const workExperiences = {
  display: true,
  experience: [
    {
      role: "Senior Software Engineer",
      company: "Protectonce",
      companylogo: require("./assets/images/ProtectOnce-Final_logo_only.png"),
      date: "2022 – Present",
      desc: "Leading the development of an innovative cybersecurity product leveraging contextual security principles and cutting-edge technologies.",
      descBullets: [
        "Cybersecurity Solutions",
        "Serverless Architecture",
        "Cloud-native Development",
        "AWS Services Integration",
        "Node.js Optimization"
      ]
    },
    {
      role: "Blockchain Developer",
      company: "PrimeLab",
      companylogo: require("./assets/images/primelab.jpg"),
      date: "April 2022 – June 2022",
      desc: "Spearheaded development initiatives in NFTs and blockchain technologies, enhancing microservice management and application efficiency.",
      descBullets: [
        "Ethereum Smart Contracts",
        "Web3.js Integration",
        "NFT Creation & Management",
        "Blockchain Architecture",
        "AWS Deployment Strategies"
      ]
    },
    {
      role: "Senior Consultant",
      company: "NDS Global",
      companylogo: require("./assets/images/nds.jpeg"),
      date: "Aug 2021 – May 2022",
      desc: "Engineered enterprise-grade solutions featuring AI-powered chatbots using AWS Lex and Microsoft Azure, significantly improving business communication efficiency.",
      descBullets: [
        "Azure Cloud Integration",
        "Azure DevOps Implementation",
        "AWS Lex Chatbot Development",
        "Cross-platform Applications with Electron",
        "Modern Frontend with Vue.js & Next.js",
        "Serverless Computing with AWS Lambda"
      ]
    },
    {
      role: "Full Stack Cloud Developer",
      company: "AppGambit",
      companylogo: require("./assets/images/appgambit.jpeg"),
      date: "July 2020 – Jul 2021",
      desc: "Designed and implemented scalable cloud solutions, including a real-time user activity tracking system utilizing Elasticsearch for enhanced data insights.",
      descBullets: [
        "Microservices Architecture",
        "HL7 Integration",
        "Message Queue Management (RabbitMQ)",
        "Real-time Data Processing (Amazon Kinesis)",
        "Data Visualization (Kibana)",
        "Elasticsearch Optimization",
        "Workflow Orchestration (AWS Step Functions)",
        "Cloud Monitoring (Amazon CloudWatch)",
        "Container Orchestration (Amazon ECS)",
        "Serverless Computing (AWS Lambda)",
        "DevOps Best Practices"
      ]
    },
    {
      role: "Cloud Engineer",
      company: "AppGambit",
      companylogo: require("./assets/images/appgambit.jpeg"),
      date: "Jan 2020 – Jul 2020",
      desc: "Developed innovative cloud solutions and managed containerized applications, focusing on operational efficiency and scalability.",
      descBullets: [
        "Shell Scripting",
        "Serverless Functions (AWS Lambda)",
        "Voice UI Development (Alexa Skills)",
        "Conversational AI (Amazon Lex)",
        "Serverless Framework Implementation"
      ]
    },
    {
      role: "Software Developer",
      company: "Bynebits",
      companylogo: require("./assets/images/bynebits.jpeg"),
      date: "Dec 2017 – July 2019",
      desc: "Engineered scalable web solutions, including the RenagatePartner platform, leveraging Node.js and AWS serverless technologies for optimal performance.",
      descBullets: [
        "Node.js Backend Development",
        "Elasticsearch Integration",
        "AWS Lambda Function Development",
        "MongoDB Database Management"
      ]
    },
    {
      role: "Full Stack Developer",
      company: "Creative Infotech",
      companylogo: require("./assets/images/creative.png"),
      date: "Aug 2016 – Sep 2017",
      desc: "Developed a robust e-commerce platform, enhancing user experiences with real-time cart management and optimized database operations.",
      descBullets: [
        "PHP Backend Development",
        "Apache Server Configuration",
        "MySQL Database Optimization",
        "PhpMyAdmin Utilization"
      ]
    }
  ]
};

// Open Source Contribution Section
const openSource = {
  showGithubProfile: "true",
  display: true
};

// Major Projects Section
const bigProjects = {
  title: "Flagship Projects",
  subtitle: "Transformative solutions empowering startups and enterprises through cutting-edge technology",
  projects: [
    {
      image: require("./assets/images/ProtectOnce-Final_logo_only.png"),
      projectName: "Protectonce",
      projectDesc: "A revolutionary SaaS platform offering an agentless, API-driven application security solution. ProtectOnce provides unparalleled API insights and protection against sophisticated cyber threats, deployable within minutes.",
      footerLink: [
        {
          name: "Learn More",
          url: "http://protectonce.com/"
        }
      ]
    },
    {
      image: require("./assets/images/primelab.jpg"),
      projectName: "PrimeLab",
      projectDesc: "At the forefront of Web3 innovation, PrimeLab is developing next-generation infrastructure and tooling, setting new standards in blockchain and decentralized technologies.",
      footerLink: [
        {
          name: "Explore Project",
          url: "http://primelab.io/"
        }
      ]
    }
  ],
  display: true
};

// Achievements and Certifications Section
const achievementSection = {
  title: emoji("Achievements & Certifications 🏆"),
  subtitle: "Professional accolades and industry-recognized certifications showcasing expertise and commitment to excellence",

  achievementsCards: [
    {
      title: "AWS Certified Developer – Associate",
      subtitle:
        "Demonstrates proficiency in developing and maintaining AWS-based applications, showcasing expertise in core AWS services, uses, and best practices for building secure and robust cloud applications.",
      image: require("./assets/images/awsdeveloper.png"),
      imageAlt: "AWS Developer Associate Certificate",
      footerLink: [
        {
          name: "View Credential",
          url: "https://www.credly.com/badges/f7362a39-8446-4401-8814-fb61654b232b/public_url"
        }
      ]
    },
    {
      title: "AWS Certified Solutions Architect – Associate",
      subtitle:
        "Validates the ability to design and deploy scalable, highly available, and fault-tolerant systems on AWS. This certification highlights expertise in translating business requirements into robust AWS-based solutions.",
      image: require("./assets/images/aws-certified-solutions-architect-associate.png"),
      imageAlt: "AWS Solutions Architect Associate Certificate",
      footerLink: [
        {
          name: "View Credential",
          url: "https://www.credly.com/badges/da24d87e-4a4d-49a7-9c25-297c646172d0/public_url"
        }
      ]
    },
    {
      title: "AWS Certified Cloud Practitioner",
      subtitle:
        "Demonstrates a solid understanding of AWS Cloud concepts, services, and terminology. This foundational certification is a testament to comprehensive knowledge of cloud computing and its business applications.",
      image: require("./assets/images/aws-certified-cloud-practitioner.png"),
      imageAlt: "AWS Cloud Practitioner Certificate",
      footerLink: [
        {
          name: "View Credential",
          url: "https://www.credly.com/badges/224fef56-9b49-45d7-bfc4-d3249910c849/public_url"
        }
      ]
    }
  ],
  display: true
};

// Blog Section Configuration
const blogSection = {
  title: "Tech Insights",
  subtitle:
    "Sharing knowledge and experiences in software development, cloud computing, and emerging technologies.",
  displayMediumBlogs: "true", // Set to false to display hardcoded blogs instead of fetched Medium posts
  blogs: [
    {
      url: "https://medium.com/@ladumorrahul/serverless-architecture-revolutionizing-cloud-computing-f3a1d4e7c0b8",
      title: "Serverless Architecture: Revolutionizing Cloud Computing",
      description:
        "Explore how serverless computing is transforming the landscape of cloud-based applications, offering scalability and cost-efficiency."
    },
    {
      url: "https://medium.com/@ladumorrahul/mastering-aws-lambda-best-practices-for-efficient-serverless-functions-7ae54321b9c2",
      title: "Mastering AWS Lambda: Best Practices for Efficient Serverless Functions",
      description:
        "Dive into expert tips and strategies for optimizing AWS Lambda functions, enhancing performance and reducing costs in serverless applications."
    }
  ],
  display: true // Set to false to hide this section
};

// Talks Section Configuration
const talkSection = {
  title: "Conference Presentations",
  subtitle: emoji(
    "Sharing insights and experiences at tech conferences and meetups 🎤"
  ),

  talks: [
    {
      title: "Serverless Architecture: The Future of Cloud Computing",
      subtitle: "Tech Talk at Cloud Native Conference 2023",
      slides_url: "https://slideshare.net/RahulLadumor/serverless-architecture-talk",
      event_url: "https://cloudnativecon.com/2023"
    }
  ],
  display: false // Set to true to display this section
};

// Podcast Section Configuration
const podcastSection = {
  title: emoji("Tech Podcasts 🎙️"),
  subtitle: "Discussions on cutting-edge technologies and software development practices",

  // Podcast embedded link
  podcast: [
    "https://anchor.fm/cloudcast/embed/episodes/Serverless-Computing-with-Rahul-Ladumor-e1n3q5t"
  ],
  display: false // Set to true to display this section
};

// Contact Information
const contactInfo = {
  title: emoji("Get in Touch ☎️"),
  subtitle:
    "Open for exciting project collaborations, tech discussions, or career opportunities. Let's connect!",
  number: "+91-7567611653",
  email_address: "rahuldladumor@gmail.com"
};

// Twitter Profile Configuration
const twitterDetails = {
  userName: "Rahul__ladumor", // Twitter username without @
  display: true // Set to false to hide this section
};

const isHireable = true; // Set to false if not open to job opportunities

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
